<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Keyword") }}</label>
          <vs-input
            v-model="NewsSearch.NewsContent"
            class="w-full"
            name="Description"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateFrom") }}</label>
          <datepicker
            placeholder="from"
            v-model="NewsSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateTo") }}</label>
          <datepicker
            placeholder="To"
            v-model="NewsSearch.DateTo"
            class="vx-col w-full"
            name="To"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            @click="NewsSearchFirst"
            color="warning"
            >{{ $t("Search") }}</vs-button
          >
        </div>
        <div class="vx-row">
            <vs-button
              type="border"
              class="ml-5"
              icon-pack="feather"
              icon="icon-plus"
              @click="addNewData"
              >{{ $t("AddNew") }}</vs-button
            >
          </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in News"
        :key="item.ID"
        @click="$router.push(item.url).catch(() => {})"
      >
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)"
            />
          </div>
          
          <div class="vx-col">
            <imageLazy v-if="item.MainImgURL !=null"
            :imageStyle="{
                'border-radius': '10px',
                width: '300px',
                height: '200px',
                size: '50px',
              }"
              :imageClass="'ml-3'"
              :src="baseURL + item.MainImgURL"
              placeHolderType="News"
             
          />

            <div class="vx-col mb-3 mt-3">

              <h2 class="mb-2">
                {{ item.NewsHeasline }} 
              </h2>

              <p>{{$t("NewsDate")}}: {{item.NewsDate | formatDate("yyyy/MM/dd HH:mm:ss")}}</p>
              
              <p>{{$t("AuthorName")}}: {{item.AuthorName }}</p>


              <!-- <p>
                {{item.NewsContent}}
              </p> -->

              <small @click="editData(item)"
                >{{ $t("EditNews") }} </small
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleNews from "@/store/News/moduleNews.js";
import { domain } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";
import imageLazy from "@/components/image_lazy.vue";


export default {
  components: {
    Datepicker,
    imageLazy
  },

  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    NewsSearch() {
      debugger;
      return this.$store.state.NewsList.NewsSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    News() {
      return this.$store.state.NewsList.News;
    },
  },

  methods: {
    NewsDataSearch() {
      debugger;
      this.$store
        .dispatch("NewsList/SearchNews", this.NewsSearch)
        .then((res) => {
          if (
            document.documentElement.scrollHeight <= window.innerHeight &&
            res.data.Data.length >= this.NewsSearch.PageSize
          ) {
            this.NewsSearch.PageNumber++;
            this.NewsDataSearch();
          }
        });
    },
    NewsSearchFirst() {
      debugger;
      this.NewsSearch.PageNumber = 1;
      this.NewsDataSearch();
    },
    addNewData() {
      debugger;
      // this.sidebarData = {};
      this.$router.push({ name: "News_Edit" });
    },

    deleteData(data) {
      debugger;
      this.$store.dispatch("NewsList/DeleteNews", data).catch((err) => {
        console.error(err);
      });
    },

    editData(data) {
      this.$router.push({ name: "News_Edit", params: { ID: data.ID } });
    },

    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    if (!moduleNews.isRegistered) {
      this.$store.registerModule("NewsList", moduleNews);
      moduleNews.isRegistered = true;
    }

    this.$store.commit("NewsList/SET_News", []);
    this.NewsSearchFirst();
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
